import React, { useEffect,useState,useRef} from 'react'
import AgoraRTC from "agora-rtc-sdk-ng"
import { useNavigate,useLocation} from 'react-router-dom';
import Feedback from './Feedback';
import { _fnUpdateMeet, createAgoraTocken, getCurrentDateTime, } from './Callapi';

export default  function Video()
{
    
    const navigate = useNavigate();
    const totalSeconds = 900;
    const [minutes, setMinutes] = useState(totalSeconds/60);
    const [seconds, setSeconds] = useState(0);
    let [remoteID, setRemoteID] = useState("");
    const clickLeave = useRef(null);
    let totalTime=0;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const slotID = queryParams.get('s');
    const agoraTocken = queryParams.get('t');

    console.log(agoraTocken);

   

    // channel Params
    let channelParameters =
    {
        // A variable to hold a local audio track.
        localAudioTrack: null,
        // A variable to hold a local video track.
        localVideoTrack: null,
        // A variable to hold a remote audio track.
        remoteAudioTrack: null,
        // A variable to hold a remote video track.
        remoteVideoTrack: null,
        // A variable to hold the remote user id.s
        remoteUid: null,
    };

    async function startBasicCall()
    {
        // Create an instance of the Agora Engine
        const agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp9" });

        // Dynamically create a container in the form of a DIV element to play the remote video track.
        const remotePlayerContainer =  document.getElementById("remoteStreamDiv");

        // Dynamically create a container in the form of a DIV element to play the local video track.
        const localPlayerContainer =  document.getElementById("localStreamDiv");

        // localPlayerContainer.textContent = "User " + options.uid.toString();

        agoraEngine.on("user-published", async (user, mediaType) =>
        {
        // Subscribe to the remote user when the SDK triggers the "user-published" event.
        await agoraEngine.subscribe(user, mediaType);
        console.log("subscribe success");
        // Subscribe and play the remote video in the container If the remote user publishes a video track.
        if (mediaType === "video")
        {
            // Retrieve the remote video track.
            channelParameters.remoteVideoTrack = user.videoTrack;
            // Retrieve the remote audio track.
            channelParameters.remoteAudioTrack = user.audioTrack;
            // Save the remote user id for reuse.
            channelParameters.remoteUid = user.uid.toString();
            // remotePlayerContainer.textContent = "Remote user " + user.uid.toString();
            // Play the remote video track.
            channelParameters.remoteVideoTrack.play(remotePlayerContainer);
            // set remote ID to show countDown timer 
            setRemoteID(channelParameters.remoteVideoTrack._uintId);
            // show countdown time in showTimer id div
            var showTimer = document.getElementById("showTimer");
            showTimer.style.display="inline-block";
        }

        // Subscribe and play the remote audio track If the remote user publishes the audio track only.
        if (mediaType === "audio")
        {
            // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
            channelParameters.remoteAudioTrack = user.audioTrack;
            // Play the remote audio track. No need to pass any DOM element.
            channelParameters.remoteAudioTrack.play();
        }

        // Listen for the "user-unpublished" event.
        agoraEngine.on("user-unpublished", user =>
        {
            // cloce video and audio track
            channelParameters.localAudioTrack.close();
            channelParameters.localVideoTrack.close();
            
            // get how many seconds meeting done
            const element = document.getElementById('leave');
            totalTime = element.getAttribute('data_val');

            // set value in json to update slot table
            const currentDate = getCurrentDateTime();
                let jsonBody = {
                    "id": "743",
                    "model": "slot_model",
                    "model_location": "admin/slot_model",
                    "MeetingStatus": "2",
                    "MeetingStatusUpdateTime": currentDate,
                    "total_done_meet_time": totalTime
                };

               _fnUpdateMeet(jsonBody);
                navigate('/feedback');
        });
            });

        window.onload = async function ()
        {

            let jsonBody = {
                "slot_id": slotID
              };

            // let agoraTocken =  await createAgoraTocken(jsonBody);
            // agoraTocken = queryParams.get('t');

             let options = 
             {
                 // Pass your App ID here.
                 appId: '85f49d9f83744c80b39fd5e448ed5d67',
                 // Set the channel name.
                 channel: slotID,
                 // Pass your temp token here.
                 token: agoraTocken,                 // Set the user ID.
                 uid: 0,
             };

            // Listen to the Join button click event.
            document.getElementById("join").onclick = async function ()
            { 
                // Join a channel.
                await agoraEngine.join(options.appId, options.channel, options.token, options.uid);
                // Create a local audio track from the audio sampled by a microphone.
                channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
                // Create a local video track from the video captured by a camera.
                channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
                // Append the local video container to the page body.
                // Publish the local audio and video tracks in the channel.
                await agoraEngine.publish([channelParameters.localAudioTrack, channelParameters.localVideoTrack]);
                // Play the local video track.
                channelParameters.localVideoTrack.play(localPlayerContainer);
                console.log("publish success!");
            }
        
            // Listen to the Leave button click event.
            document.getElementById('leave').onclick = function ()
            {
                // get how many seconds meeting done
                const totalTime = clickLeave.current.getAttribute('data_val');
                _fnLeaveMeet(totalTime);
            }

            //  leave meeting function 
            const _fnLeaveMeet = async (totalTime)=>{
                // Destroy the local audio and video tracks.
                channelParameters.localAudioTrack.close();
                channelParameters.localVideoTrack.close();
                // Remove the containers you created for the local video and remote video.
                removeVideoDiv(remotePlayerContainer.id);
                removeVideoDiv(localPlayerContainer.id);
                // Leave the channel
                await agoraEngine.leave();
                const currentDate = getCurrentDateTime();   
                // set value in json to update slot table
                let jsonBody = {
                    "id": "743",
                    "model": "slot_model",
                    "model_location": "admin/slot_model",
                    "MeetingStatus": "2",
                    "MeetingStatusUpdateTime":currentDate,
                    "total_done_meet_time": totalTime
                  };
                   await _fnUpdateMeet(jsonBody);
                    navigate('/feedback');
            }
        }
    }

    // Remove the video stream from the container.
    function removeVideoDiv(elementId)
    {
        console.log("Removing "+ elementId+"Div");
        let Div = document.getElementById(elementId);
        if (Div)
        {
            Div.remove();
        }
    };

    
    //useEffect to set time countdown
    useEffect(() => {
        startBasicCall();

        if(remoteID !==""){
            let myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes > 0) {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                }
                if(minutes===0 && seconds===0){
                    clickLeave.current.click();
                }
            
            }, 1000);
            return () => {
                clearInterval(myInterval);
            };
        }
    });
    return (
        <>
            <button type="button" id="join">Join</button>
            <button ref={clickLeave} type="button" data_val={totalSeconds - minutes*60 - seconds} id="leave">Leave</button>
            {/* <button  type="button" id="muteVideo">Mute</button> */}
            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 m-auto' id="">
                            
                        </div>
                    </div>
                </div>
            </section>

            <div className='container'>
                <div className='row'>
                    <div className='col-md-10 m-auto timerDiv' id='localStreamDiv'>
                <span id='showTimer'>Meeting end in {minutes} minutes, Seconds {seconds}</span>
                    {/* <h1>User Stream</h1> */}
                    </div>
                </div>

                <div className='row'>
                    {/* <h1>Remote Stream</h1> */}
                    <div className='col-md-10 m-auto timerDiv' id='remoteStreamDiv'>
                    
                    </div>
                </div>
            </div>
        </>
    )
}