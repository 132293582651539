import React, { useState } from "react";
import {Link} from "react-router-dom"
import axios from 'axios';


function Registration(){
    
    const [inputs, setInputs] = useState({name:'',email:'',phone:'',password:'',password2:''})
    const onChangeHadler = (e) => {
        
        setInputs((preState) => ({
            ...preState,
            [e.target.name]: e.target.value
        }));
    } 


    const handelSubmit = async (e) => {
        e.preventDefault();
        if(inputs.password !== inputs.password2){
            alert("Password Mistmatch!!");
            return false;
        }
            try {
                const { data } = await axios.post("/api/v1/user/save",
                    {
                        name: inputs.name,
                        phone: inputs.phone,
                        email: inputs.email,
                        password: inputs.password
                    }
                )
                //console.log(data);
                if (data.success) {
                    alert("User registered Successfully");
                    //navigate('/login')
                    //_fnGetUsers();
                }
            } catch (error) {
                //console.log("Error: "+error);
                if(typeof error.response.data !== 'undefined' ){
                    var tempR = error.response.data;
                    alert(tempR.message);
                }
        }
   }


    return (
        <>
          <section className="container h-100 pt-5 mt-5">
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                       
                            {/* registration form con------- */}
                            <div className="card bg-light">
                                <article className="card-body mx-auto" >
                                        <h4 className="card-title mt-3 text-center">Create Account</h4><br/>

                                        <form onSubmit={handelSubmit} method='post' >
                                            <div className="form-group mb-3 input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"> <i className="bi bi-person"></i> </span>
                                                </div>
                                                <input name="name" className="form-control" placeholder="Full name" type="text" onChange={onChangeHadler} value={inputs.name} required />
                                            </div> 

                                            <div className="form-group mb-3 input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"> <i className="bi bi-envelope"></i> </span>
                                                </div>
                                                <input name="email" className="form-control" placeholder="Email address" type="email" onChange={onChangeHadler} value={inputs.email} required />
                                            </div> 

                                            <div className="form-group mb-3 input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"> <i className="bi bi-phone"></i> </span>
                                                </div>
                                                
                                                <input name="phone" className="form-control" placeholder="Phone number" type="text" onChange={onChangeHadler} value={inputs.phone}  required />
                                            </div> 
                                            
                                            <div className="form-group mb-3 input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"> <i className="bi bi-key"></i> </span>
                                                </div>
                                                <input className="form-control" name="password" placeholder="Create password" type="password" onChange={onChangeHadler} value={inputs.password}  minLength={6} required />
                                            </div> 


                                            <div className="form-group mb-3 input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"> <i className="bi bi-key"></i> </span>
                                                </div>
                                                <input className="form-control"  name="password2" placeholder="Repeat password" type="password" onChange={onChangeHadler} value={inputs.password2} minLength={6} required />
                                            </div>   


                                            <div className="form-group mb-4">
                                                <button type="submit" className="btn btn-danger btn-block"> Create Account  </button>
                                            </div>       
                                            <p className="text-center">Have an account? <Link to="/login">Log In</Link> </p>                                                             
                                    </form>
                                </article>
                            </div> 
                            {/* registration form con------- */}

                    </div>
                </div>
            </section>
        </>
    )
}


export default Registration