import React, { useState } from "react";
import {Link} from "react-router-dom"


function Login(){
    //const [inputs,setInputs] = useState({email:"0",password:"33"});
    const [inputs, setInputs] = useState({phone:'',password:''})
    const onchangeHadler = (e) => {
        setInputs((preState) => ({
            ...preState,
            [e.target.phone]: e.target.value,
            [e.target.password]: e.target.value
        }));
    } 


    return (
        <>
          <section className="container h-100 pt-5 mt-5">
                <div className="row">
                    <div className="col-sm-4 offset-sm-4">
                        <div className="d-flex justify-content-center h-100">
                            <div className="user_card themeBox">
                                <div className="d-flex justify-content-center">
                                    <div className="brand_logo_container">
                                        <img src="./assets/images/login.png" width="200" className="brand_logo" alt="Logo"/>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center form_container">
                                    <form>
                                        <div className="input-group mb-3">
                                            <div className="input-group-append">
                                                <span className="input-group-text"><i className="bi bi-person"></i></span>
                                            </div>
                                            <input type="text" name="phone" className="form-control input_user"  onChange={onchangeHadler} value={inputs.phone} placeholder="Phone Number"/>
                                        </div>
                                        <div className="input-group mb-2">
                                            <div className="input-group-append">
                                                <span className="input-group-text"><i className="bi bi-key"></i></span>
                                            </div>
                                            <input type="password" name="password" className="form-control input_pass" onChange={onchangeHadler} value={inputs.password} placeholder="password"/>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="customControlInline"/>
                                                <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                            </div>
                                        </div>
                                            <div className="d-flex justify-content-center mt-3 login_container">
                                    <button type="button" name="button" className="btn login_btn">Login</button>
                                </div>
                                    </form>
                                </div>
                        
                                <div className="mt-4">
                                    <div className="d-flex justify-content-center links mb-2">
                                        Don't have an account? &nbsp;<Link to="/registration" className="ml-2">Sign Up</Link>
                                    </div>
                                    <div className="d-flex justify-content-center links">
                                        <Link to="#">Forgot your password?</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Login