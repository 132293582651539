import React from 'react'
// import logo from './logo.svg';
// import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
// import Home from './screen/Home';
import Video from './screen/Video';
import Header from './includes/Header';
import About from './screen/About';
import Login from './screen/Login';
import Registration from './screen/Registration';
import Dashboard from './screen/client/Dashboard';
import Feedback from './screen/Feedback';
import Test from './screen/Tests';
import New from './screen/New';

function App() {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element={<Video/>}>  </Route>
        <Route path="feedback" element={<Feedback/>}></Route>
        <Route path="/about" element={<About/>}>  </Route>
        <Route path="/login" element={<Login/>}>  </Route>
        <Route path="/test" element={<Test/>}>  </Route>
        <Route path="/new" element={<New/>}>  </Route>
        <Route path="/registration" element={<Registration/>}>  </Route>

        <Route path="/client/dashboard" element={<Dashboard/>}>  </Route>
        
        
        {/* <Route path="/about" element={<About />}>  </Route>
        <Route path="/contact" element={<Contact/>}>  </Route> */}
      </Routes>
    </Router>
    
  );
}

export default App;
