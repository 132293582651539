import React, { useEffect } from 'react';
import axios from 'axios';

const apiUrl = 'https://insaaf99.com/api';
const apiKey = '80898ajadsfjdsaf89ad456uug444sfsd8f9asfd989df89sfd8a8f9df';

// const [allNewsData, setAllNewsData] = useState(new Map());

const fnFindDynamic = async (jsonBody, action = 'find_dynamic') => {
  try {
    const bodyContent = {
      ...jsonBody,
      apikey: apiKey,
      action: action,
    };
    // console.log(jsonBody);
    const response = await axios.post(apiUrl, bodyContent);

    if (response.status === 200) {
      const tempReturnData = response.data;
      if (tempReturnData.error != null) {
        // Handle error here
      }
      return tempReturnData;
    } else {
      // Handle error here
      return null;
    }
  } catch (error) {
    // Handle error here
    console.error(error);
    return null;
  }
};

export const _fnGetAllNews = async () => {
    try {
        let jsonBody = {
            sql:
            "SELECT *  FROM `slot` WHERE `slot_status` = '1' ORDER BY id DESC LIMIT 20",
          };
          
          let returnData = await fnFindDynamic(jsonBody, 'raw_query');
          if (returnData != null && returnData.success != null) {
              if (returnData.returnVal !=null) {
        const newsDataMap = new Map(returnData.returnVal.map((item) => [item.id, item]));
        // setAllNewsData(newsDataMap);
        console.log(newsDataMap);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const _fnUpdateMeet = async (jsonBody)=> {

try {
  let returnData = await fnFindDynamic(jsonBody,'save');
  if (returnData != null && returnData['success'] != null) {
    console.log(returnData);
  } else {
    // themeAlert(context, "Something went wrong!!");
  }

} catch (error) {
  console.error(error);
}

}

export const getCurrentDateTime = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};



export const createAgoraTocken = async (jsonBody) => {
  try {
    const response = await fnFindDynamic(jsonBody,'create_token');
    const tokenRes = response.returnVal;
    if(tokenRes.length ===1){
      const token = tokenRes[0]['accessToken'];
      return token;
    }else{
      return 0;
    }
  } catch (error) {
    // Handle error here
    console.error(error);
    return null;
  }
};

// PageA component
const Callapi = () => {
  // Some content and JSX for PageA
  return <div>PageA</div>;
};  

export default Callapi;
