import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar,faStarHalf,faStarOfLife } from '@fortawesome/free-solid-svg-icons';

const Feedback = () => {
  const [rating, setRating] = useState(0);

  // Function to handle star rating selection
  const handleRating = (selectedRating) => {
    setRating(selectedRating);
  };

  return (
    <div>
      <h1>Write Your Review</h1>
      <div>
        {[1, 2, 3, 4, 5].map((index) => (
          <FontAwesomeIcon
            key={index}
            icon={faStarOfLife}
            className={index <= rating ? 'star filled' : 'star'}
            onClick={() => handleRating(index)}
          />
        ))}
      </div>
      <button onClick={() => alert(`You rated ${rating} stars!`)}>Submit Review</button>
    </div>
  );
};

export default Feedback;
