import React from 'react'
const { fnCreateToken } = require("./Callapi");

export default  function New()  {
  console.log("test------");

  const getCurrentDateTime = () => {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  // const getTocken = async () =>  {
  //   fnCreateToken =  await require("./Callapi");
  // };

  
  
    // console.log("=====");
    // let jsonBody = {
    //   "slot_id": "785"
    // };

    // let mydata =  fnCreateToken(jsonBody);

    
    

    // console.log("======<<<"+mydata);





// console.log("----------------------");
// console.log(mydata);
//   return (
//     <div>
//      <h1>hello</h1>
//     </div>
//   )
}
