import React, { useEffect } from 'react';
import axios from 'axios';

const apiUrl = 'https://insaaf99.com/api';
const apiKey = '80898ajadsfjdsaf89ad456uug444sfsd8f9asfd989df89sfd8a8f9df';

// const [allNewsData, setAllNewsData] = useState(new Map());

const fnFindDynamic = async (jsonBody, action = 'find_dynamic') => {
  try {
    const bodyContent = {
      ...jsonBody,
      apikey: apiKey,
      action: action,
    };
    // console.log(jsonBody);
    const response = await axios.post(apiUrl, bodyContent);

    if (response.status === 200) {
      const tempReturnData = response.data;
      if (tempReturnData.error != null) {
        // Handle error here
      }
      return tempReturnData;
    } else {
      // Handle error here
      return null;
    }
  } catch (error) {
    // Handle error here
    console.error(error);
    return null;
  }
};

export const _fnGetAllNews = async () => {
    try {
        let jsonBody = {
            sql:
            "SELECT *  FROM `slot` WHERE `slot_status` = '1' ORDER BY id DESC LIMIT 20",
          };
          
          let returnData = await fnFindDynamic(jsonBody, 'raw_query');
          if (returnData != null && returnData.success != null) {
              if (returnData.returnVal !=null) {
        const newsDataMap = new Map(returnData.returnVal.map((item) => [item.id, item]));
        // setAllNewsData(newsDataMap);
        console.log(newsDataMap);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const _fnUpdateMeet = async (jsonBody)=> {

try {
  let returnData = await fnFindDynamic(jsonBody,'save');
  if (returnData != null && returnData['success'] != null) {
    console.log(returnData);
  } else {
    // themeAlert(context, "Something went wrong!!");
  }

} catch (error) {
  console.error(error);
}

}


// PageA component
const Tests = () => {
  // Some content and JSX for PageA
  return <div>PageA</div>;
};


export default Tests;
